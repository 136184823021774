<template>
  <div class="site-footer">
    <footer class="h-100 text-center text-sm-left" ref="stickyCTALeave">
      <b-container class="h-100 d-flex flex-column">
        <b-row class="footer-items mt-3 pt-5 pb-5">
          <b-col>
            <b-row>
              <b-col sm="2" md="3" lg="6">
                <img
                  src="https://assets.bungalow.com/logos/bungalow_symbol_white.svg"
                  alt="Bungalow"
                  class="footer-logo"
                />
                <p class="address font-xsmall py-4">
                  215 NW 24th Street<br />
                  Miami, FL 33127<br />
                  Suite 401
                </p>
              </b-col>

              <b-col cols="4" sm="3" lg="2" class="link-list">
                <b-link class="d-block text-white" :to="{ path: '/homeowners' }">
                  <strong>Homeowners</strong>
                </b-link>

                <b-link class="d-block text-white" :to="{ path: '/community' }">
                  <strong>Community</strong>
                </b-link>

                <b-link class="d-block text-white" :to="{ path: '/reviews' }">
                  <strong>Reviews</strong>
                </b-link>

                <b-link class="d-block text-white" :to="{ path: '/about' }">
                  <strong>About</strong>
                </b-link>

                <b-link class="d-block text-white" :to="{ path: '/radar' }">
                  <strong>Investors</strong>
                </b-link>
              </b-col>

              <b-col cols="4" sm="3" lg="2" class="link-list">
                <b-link class="d-block text-white" :to="{ path: '/articles' }">
                  <strong>Articles</strong>
                </b-link>

                <b-link class="d-block text-white" :to="{ path: '/press' }">
                  <strong>Press</strong>
                </b-link>

                <b-link class="d-block text-white" :to="{ path: '/refer' }">
                  <strong>Refer</strong>
                </b-link>

                <b-link class="d-block text-white" :to="{ path: '/careers' }">
                  <strong>Careers</strong>
                </b-link>

                <b-link class="d-block text-white" :href="publicApiDocUrl" target="_blank">
                  <strong>API Docs</strong>
                </b-link>
              </b-col>

              <b-col cols="4" sm="3" lg="2" class="link-list">
                <b-link class="d-block text-white" :to="{ path: '/privacy-policy' }">
                  <strong>Privacy Policy</strong>
                </b-link>

                <b-link class="d-block text-white" :to="{ path: '/terms-of-service' }">
                  <strong>Terms of Use</strong>
                </b-link>

                <b-link class="d-block text-white" :to="{ path: '/privacy-policy' }">
                  <strong>CA Privacy</strong>
                </b-link>

                <b-link
                  v-if="activeUserIsResident"
                  :to="{ name: 'supportIssueList' }"
                  class="d-block text-white"
                >
                  <strong>Resident Support</strong>
                </b-link>
                <b-link v-else :to="{ path: '/contact' }" class="d-block text-white">
                  <strong>Contact Us</strong>
                </b-link>

                <b-link class="d-block text-white" :to="{ path: '/faq' }">
                  <strong>FAQ</strong>
                </b-link>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <!-- Compliance -->
        <b-row class="site-footer__compliance mb-5">
          <b-col class="text-center charcoal-30--txt font-xs">
            Bungalow Living, Inc. subsidiaries hold real estate brokerage licenses in multiple
            states. A list of our real estate licenses is available
            <a href="https://bungalow.com/real-estate-licenses">here</a>.<br />
            <a href="https://downloads.bungalow.com/Bungalow+Signed+NY+SOP.pdf">
              442-H New York Standard Operating Procedure</a
            >
            <span> • </span>
            <a href="https://downloads.bungalow.com/NY+Fair+Housing+Notice.pdf">
              New York Fair Housing Notice</a
            >
            <br />
            TREC:
            <a href="https://downloads.bungalow.com/TREC+Information+About+Brokerage+Services.pdf">
              Brokerage Services</a
            >
            <span> • </span>
            <a href="https://downloads.bungalow.com/TREC+Consumer+Protection+Notice.pdf">
              Consumer Protection Notice</a
            >

            <br />Bungalow Living Property Management, Inc. California DRE# 02173292 <br />Bungalow
            Living Brokerage, Inc. California DRE# 02194922 <br />Bungalow Living Property
            Management, Inc. Colorado DRE #EC100099295<br />Bungalow Living Brokerage, Inc. Colorado
            DRE #EC100096043<br />Bungalow Living Property Management, Inc. Illinois License #
            478027611
          </b-col>
        </b-row>

        <b-row class="flex-grow-1 align-items-end pt-2 footer-copy-social">
          <b-col sm="7">
            <small class="legal">
              &copy; {{ currentYear }} Bungalow Living, Inc. All rights reserved.
            </small>
          </b-col>
          <b-col sm="5" class="social-links-container">
            <a
              v-for="(link, index) in socialLinks"
              :key="index"
              :href="link.url"
              target="_blank"
              rel="noopener"
            >
              <span class="sr-only"> {{ link.sr }}</span>
              <inline-svg :src="getSocialIcon(link.icon)" :class="{ inverted: link.inverted }" />
            </a>
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SiteFooter',
  data() {
    return {
      socialLinks: [
        {
          icon: 'twitter',
          inverted: false,
          url: 'https://twitter.com/livebungalow',
          sr: 'Twitter',
        },
        {
          icon: 'facebook',
          inverted: false,
          url: 'https://www.facebook.com/livebungalow/',
          sr: 'Facebook',
        },
        {
          icon: 'instagram',
          inverted: false,
          url: 'https://www.instagram.com/livebungalow/',
          sr: 'Instagram',
        },
        {
          icon: 'medium',
          inverted: true,
          url: 'https://medium.com/@Bungalow',
          sr: 'Medium',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['activeUserIsResident']),

    currentYear() {
      return new Date().getFullYear()
    },
    publicApiDocUrl() {
      return process.env.VUE_APP_PUBLIC_API_DOCS
    },
  },
  methods: {
    getSocialIcon(icon) {
      return require(`@/assets/logos/${icon}.svg`)
    },
  },
}
</script>

<style lang="scss">
.site-footer {
  background: $charcoal-80;
  min-height: $default-footer-height;

  &__compliance {
    a {
      color: $blue-40;
      text-decoration: underline;
      &:hover {
        color: $blue-20;
        text-decoration: underline;
      }
    }
  }

  .address {
    color: $text-lightest;

    @include mobile {
      margin-bottom: 2rem;
    }
  }

  .link-list {
    text-align: left;
    a {
      font-size: 15px;
      margin-bottom: 1rem;

      @include mobile {
        font-size: 14px;
      }
    }
  }
  .footer-copy-social {
    border-top: 1px solid $charcoal-40;
    padding-bottom: 1.5rem;

    @include mobile {
      padding-bottom: 7rem;
    }

    .social-links-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      svg {
        width: 20px;
        height: 20px;
        margin-left: 2rem;
        border: 1px solid white;
        border-radius: 2px;

        &:not(.inverted) {
          border: none;
          path {
            fill: white;
          }
        }
      }

      @include mobile {
        padding-top: 1.25rem !important;
        justify-content: center;
      }
    }
  }
  @include mobile {
    .footer-items {
      padding-bottom: 0 !important;
    }

    .link-list {
      margin-bottom: 3rem;
    }
  }

  .footer-logo {
    width: 1.25rem;
  }

  .legal {
    color: $charcoal-40;
  }
  hr {
    border-color: $charcoal-40;
    border-width: 1px;
  }
}
</style>
